<template>
  <svg
    width="196"
    height="74"
    viewBox="0 0 196 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_212_7)">
      <mask
        id="mask0_212_7"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="196"
        height="74"
      >
        <path d="M0 -7.62939e-05H196V73.9949H0V-7.62939e-05Z" fill="white" />
      </mask>
      <g mask="url(#mask0_212_7)">
        <path
          d="M118.955 49.3052C117.796 49.3052 117.001 48.9564 116.569 48.2592C116.136 47.5623 116.152 46.6341 116.616 45.4759L123.126 28.6671C123.59 27.5102 124.309 26.5814 125.284 25.8838C126.256 25.1869 127.312 24.8385 128.446 24.8385H141.957L140.781 27.8871H132.771C132.062 27.8871 131.423 28.319 131.16 28.9779L124.63 45.2928C124.446 45.7534 124.785 46.255 125.282 46.255H133.641L132.465 49.3052H118.955Z"
          fill="#F15930"
        />
        <path
          d="M138.813 49.3052C137.655 49.3052 136.859 48.9564 136.427 48.2592C135.994 47.5623 136.01 46.6341 136.474 45.4759L142.984 28.6671C143.448 27.5102 144.167 26.5814 145.142 25.8838C146.115 25.1869 147.17 24.8385 148.304 24.8385H161.815L160.639 27.8871H152.629C151.92 27.8871 151.282 28.319 151.018 28.9779L144.488 45.2928C144.303 45.7534 144.643 46.255 145.14 46.255H153.499L152.323 49.3052H138.813Z"
          fill="#F15930"
        />
        <path
          d="M162.962 49.3052H154.806L164.297 24.8385H172.453L162.962 49.3052Z"
          fill="#F15930"
        />
        <path
          d="M79.5443 41.1497H82.6295L80.8045 45.8089C80.6989 46.0784 80.4395 46.2556 80.1508 46.2556H76.5499C76.0559 46.2556 75.7164 45.7587 75.8952 45.2984L82.4889 28.3345C82.5939 28.0649 82.8539 27.8871 83.1436 27.8871H83.1495H86.7442H95.8588C96.0595 27.078 95.9948 26.4055 95.6595 25.8723C95.2253 25.183 94.4291 24.8378 93.2709 24.8378H79.5123C78.3776 24.8378 77.3233 25.1869 76.3499 25.8832C75.3759 26.5814 74.6562 27.5099 74.1923 28.6674L67.6827 45.4756C67.2188 46.6341 67.2026 47.5623 67.6348 48.2592C68.0668 48.9564 68.863 49.3052 70.0215 49.3052H83.7801C84.9145 49.3052 85.9691 48.9564 86.9422 48.2592C87.9166 47.5623 88.6355 46.6341 89.1008 45.4756L91.5759 39.094C91.7617 38.6149 91.4086 38.0995 90.8952 38.0995H80.7203L79.5443 41.1497Z"
          fill="#F15930"
        />
        <path
          d="M195.476 25.8838C195.043 25.1873 194.248 24.8385 193.089 24.8385H179.331C178.196 24.8385 177.142 25.1873 176.168 25.8838C175.194 26.5814 174.475 27.5106 174.01 28.6674L166.011 49.3052H174.158L182.067 28.9179H182.081L182.307 28.3355C182.412 28.0652 182.672 27.8871 182.962 27.8871H186.562C187.056 27.8871 187.395 28.383 187.216 28.8433L183.43 38.5964H179.887L178.705 41.646H182.254L179.272 49.3052H187.428L195.429 28.6674C195.893 27.5106 195.908 26.5814 195.476 25.8838Z"
          fill="#F15930"
        />
        <path
          d="M120.125 25.8838C119.692 25.1873 118.897 24.8385 117.739 24.8385H103.98C102.846 24.8385 101.791 25.1873 100.817 25.8838C99.8434 26.5814 99.1238 27.5106 98.6595 28.6674L92.1496 45.4759C91.686 46.6344 91.6698 47.5623 92.1027 48.2595C92.535 48.9567 93.3309 49.3052 94.4887 49.3052H98.8585L100.32 45.5379C100.322 45.4588 100.332 45.3789 100.363 45.2987L106.7 28.9941C106.96 28.3269 107.602 27.8871 108.319 27.8871H111.21C111.704 27.8871 112.044 28.383 111.865 28.8436L105.527 45.1489C105.268 45.8158 104.626 46.255 103.91 46.255H101.617L100.433 49.3052H108.248C109.382 49.3052 110.437 48.9567 111.41 48.2595C112.384 47.5623 113.104 46.6344 113.568 45.4759L120.078 28.6674C120.542 27.5106 120.558 26.5814 120.125 25.8838Z"
          fill="#F15930"
        />
        <path
          d="M22.0549 24.2073C22.0021 24.2073 21.945 24.2113 21.8826 24.2192C17.3482 24.8432 13.1012 26.6332 9.60061 29.3964C4.58578 33.3553 1.41052 39.0208 0.418307 45.7809C-1.10647 56.1659 4.32511 63.1579 7.69835 66.4008C14.17 72.6223 21.6312 73.928 26.7506 73.928C31.7123 73.928 38.3578 73.0876 45.7643 66.5338C53.241 59.9166 56.2347 49.8028 53.5785 40.1391C53.4845 39.7972 53.2297 39.523 52.8968 39.4033C52.7774 39.3607 52.653 39.3396 52.5296 39.3396C52.3078 39.3396 52.0887 39.4079 51.903 39.5379L45.4254 44.1079C45.073 44.3567 44.9007 44.7896 44.9859 45.2127C46.0246 50.3433 44.0748 55.3997 39.4969 59.4514C34.4979 63.8746 30.2486 64.4711 26.7506 64.4711C23.3685 64.4711 18.4549 63.6235 14.2531 59.5824C11.7335 57.1614 8.91131 53.0388 9.77483 47.1548L9.79991 46.9826C10.2143 44.1479 10.9079 39.4033 15.6258 34.3914C18.2635 31.5893 19.8748 29.8455 21.0125 28.5619C22.9877 26.3336 23.4516 25.6823 23.1108 24.894C22.9309 24.4769 22.5161 24.2073 22.0549 24.2073Z"
          fill="#F15930"
        />
        <path
          d="M51.7619 34.8407L49.301 31.9208C47.991 30.365 46.7309 28.8653 46.6058 28.7106C38.23 18.0355 34.6743 10.6789 33.201 6.02805H48.1075C49.4696 6.02805 50.5836 4.91342 50.5836 3.55132V2.69176C50.5836 1.32933 49.4696 0.215031 48.1075 0.215031H25.5253C24.4248 0.215031 23.4877 0.942604 23.167 1.93976C23.1452 1.96418 23.1287 1.99388 23.1241 2.03479C23.0898 2.26775 21.925 9.81903 13.9336 21.6318C13.665 22.0275 13.6877 22.5528 13.989 22.9246C14.1995 23.186 14.5133 23.3282 14.8344 23.3282C14.9706 23.3282 15.1092 23.3031 15.2416 23.2493C17.0554 22.5168 18.957 22.0106 20.8929 21.744C21.2502 21.6955 21.6627 21.6717 22.1913 21.6717C22.5595 21.6717 22.9393 21.6833 23.3072 21.6945C23.594 21.7021 23.8758 21.7107 24.1454 21.714H24.1539C24.5162 21.714 24.8512 21.5378 25.0541 21.2428C26.5808 19.0241 26.9844 18.1259 27.679 16.551C30.1768 21.4975 33.6563 26.9067 38.0468 32.6629L39.2136 34.1547C37.5819 36.4711 31.691 44.714 27.6235 50.395C27.4836 50.5913 27.4124 50.8292 27.4213 51.0694L27.4345 51.4228C27.4496 51.8218 27.6816 52.1804 28.0393 52.357C28.1921 52.4329 28.3564 52.4695 28.521 52.4695C28.7418 52.4695 28.9622 52.4032 29.1486 52.2712L51.5576 36.4292C51.8068 36.2536 51.9704 35.9817 52.0087 35.6801C52.048 35.3779 51.9576 35.0733 51.7619 34.8407Z"
          fill="#F15930"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_212_7">
        <rect width="196" height="73.9949" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
